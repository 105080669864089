<template>
  <div class="pagination clearfix style3">
    <div class="nav-link">
      <a class="page-numbers" @click="previousPage">
          <i class="icon fa fa-angle-left" aria-hidden="true"></i>
      </a>

      <a class="page-numbers" v-if="(current - (pageInterval/2))>0" @click="previousPage">
          <i class="icon fas fa-ellipsis-h" aria-hidden="true"></i>
      </a>

      <template v-for="n in totalPages">
          <a v-if="inIntervalPage(n)" :key="n"
          class="page-numbers"
          v-bind:class="[{'current': isActive(n)}]"
          @click="setPage(n)">
            {{n}}
          </a>
      </template>

      <template v-if="totalPages>pageInterval">
        <a class="page-numbers" v-if="totalPages>(current + (pageInterval/2))" @click="nextPage">
            <i class="icon fas fa-ellipsis-h" aria-hidden="true"></i>
        </a>

        <a class="page-numbers" v-if="totalPages>=(current + (pageInterval/2))" @click="setPage(totalPages)">
            {{totalPages}}
        </a>
      </template>

      <a class="page-numbers" @click="nextPage">
        <i class="icon fa fa-angle-right" aria-hidden="true"></i>
      </a>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations} from "vuex";
export default {
  name:'GridPagination',
  data() {
    return {
      current: 1,
      pageInterval:11,
    }
  },
  computed: {
    ...mapGetters({
      totalPages: "ecommerce/totalPages"
    }),
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts'
    }),
    ...mapMutations({
      setPageNumber: 'ecommerce/SET_PAGE_NUMBER'
    }),
    setPage(num) {
      this.current=num;
      this.setPageNumber(num);
      this.loadProducts();
    },
    isActive: function(i) {
      return (i==this.current);
    },
    previousPage() {
      if (this.current > 1) {
        this.current = this.current - 1;
        this.setPageNumber(this.current);
        this.loadProducts();
      }
    },
    nextPage() {
      if (this.current < this.totalPages) {
        this.current = this.current + 1;
        this.setPageNumber(this.current);
        this.loadProducts();
      }
    },
    inIntervalPage(index) {
      if(this.totalPages<=this.pageInterval) {return true;}

      let limitprev = this.current - (this.pageInterval/2);
      let limitnext = this.current + (this.pageInterval/2);

      if (limitprev<0) {
        limitprev=0;
        limitnext=this.pageInterval;
      }

      if (index>limitprev && index<limitnext) {
        return true;
      }

      return false;
    },
  },
};
</script>


