<template>
  <div>
    <div class="sidebar col-lg-2 col-md-3 col-sm-12 col-xs-12">
      <div class="breadcrumb-trail breadcrumbs">
        <ul class="trail-items breadcrumb">
            <li class="trail-item trail-begin">
              <router-link :to="{ name: 'Home' }"> Principal </router-link>
            </li>
            <li class="trail-item trail-end active">Productos</li>
        </ul>
      </div>

      <div class="wrapper-sidebar shop-sidebar sidebarscroll">
        <div class="widget woof_Widget" id="widgetFiltros">
          <Filters class="mb-3" />
          <Categories class="mb-3" />

          <!-- <Price class="mb-3" /> -->

          <Brand class="mb-3" :brands="brands" @update="updateBrand"/>

          <!-- <template v-if="attributeFilters.length>0">
              <div v-for="filter in attributeFilters" :key="filter.id">
                  <template v-if="filter.class=='color'">
                      <Color class="mb-3" :filter="filter" @update="updateFilters"/>
                  </template>

                  <template v-if="filter.class=='tag'">
                      <Tag class="mb-3" :filter="filter" @update="updateFilters"/>
                  </template>

                  <template v-if="filter.class=='list'">
                      <List class="mb-3" :filter="filter" @update="updateFilters"/>
                  </template>
              </div>
          </template> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filters from "../components/Sidebar/Filters.vue";
import Categories from "../components/Sidebar/Categories.vue";
import Brand from "../components/Sidebar/Brand.vue";
/* import Tag from "./Sidebar/Tag.vue";
import List from "./Sidebar/List.vue";
import Color from "../components/Sidebar/Color.vue"; */

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "TiendaIndex",
  components: {
    Categories,
    Brand,
   /*  Tag,
    List,
    Color, */
    Filters
  },
  data() {
    return {
        filters:{
            category:[],
            brand:[],
            attList:[],
        }
    };
  },
  mounted() {
    this.loadAttGroups({activefilter: true});
    if (this.searchdata !== undefined){
      if (this.searchdata.category != undefined && this.searchdata.category.length>0){this.filters.category = this.searchdata.category;}
      if (this.searchdata.brand != undefined && this.searchdata.brand.length>0){this.filters.brand = this.searchdata.brand;}
      if (this.searchdata.searchdata != undefined && this.searchdata.attList.length>0){this.filters.attList = this.searchdata.attList;}
    }
  },
  computed: {
    ...mapGetters({
      brands: "ecommerce/brands",
      attributeFilters: "ecommerce/attributeFilters", 
      searchdata: "ecommerce/searchdata"
    })
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts',
      loadAttGroups: 'ecommerce/loadAttributeList'
    }),
    ...mapMutations({
      setFilterBrand: 'ecommerce/SET_FILTER_BRAND',
      setPageNumber: 'ecommerce/SET_PAGE_NUMBER'
    }),
    updateBrand(obj) {
      this.setFilterBrand(obj);
      this.filter();
    },
    updateFilters() {
      this.filter();
    },
    filter(){
      this.setPageNumber(1);
      this.loadProducts();
    }
  },
};
</script>

<style>
.shop-sidebar {
  margin-top: 2em;
}
.mb-3 {
  margin-bottom: 3rem;
}
</style>
<style scoped>
.breadcrumb-trail {
  height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  .breadcrumb-trail {
    display: none;
  }
}
.sidebarscroll {
  max-height: 100vh;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  overflow-x: hidden;
}
.sidebarscroll::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}
.sidebarscroll::-webkit-scrollbar-thumb {
  background-color: transparent; 
  border-radius: 5px; 
  transition: background-color 0.3s ease;
}
.sidebarscroll::-webkit-scrollbar-track {
  background-color: transparent; 
}
.sidebarscroll:hover::-webkit-scrollbar-thumb {
  background-color: #999;
}
</style>
