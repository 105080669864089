<template>
  <div class="widget widget-categories">
    <div class="mb-3">
      <h3 class="widgettitle">Categorias</h3>
      <button type="button" class="single_add_to_cart_button btn-block btn-sm button"  @click="toggleAllCategories">{{ tituloboton }}</button>
    </div>
    
    <ul class="list-categories">
      <li v-for="(category, index) in categoryTree" :key="index" class="margen">
        <div class="categoryline">
          <div>
            <input :id="'category_'+category.id" type="checkbox" v-model="selected" v-bind:value="category.id">
            <label :for="'category_'+category.id" class="label-text">{{category.name}}</label>
          </div>
          <button v-if="Array.isArray(category.children) && category.children.length > 0" class="widget-categories__expander" type="button" @click="toggleSubMenu($event)"></button>
        </div>
        <ul v-if="Array.isArray(category.children) && category.children.length > 0" class="list-categories widget-categories__item">
          <li v-for="(subcategory, index) in category.children" :key="index" class="mb-1">
            <div class="categoryline paddingleft">
              <div>
              <input :id="'category_'+subcategory.id" type="checkbox" v-model="selected" v-bind:value="subcategory.id">
              <label :for="'category_'+subcategory.id" class="label-text2">{{subcategory.name}}</label>
              </div>
              <button v-if="Array.isArray(subcategory.children) && subcategory.children.length > 0" class="widget-categories__expander" type="button" @click="toggleSubMenu($event)"></button>
            </div>
            <ul v-if="Array.isArray(subcategory.children) && subcategory.children.length > 0" class="list-categories widget-categories__item">
              <li v-for="(sucsubcategory, index) in subcategory.children" :key="index">
                <div class="categoryline paddingleft2">
                  <input :id="'category_'+sucsubcategory.id" type="checkbox" v-model="selected" v-bind:value="sucsubcategory.id">
                  <label :for="'category_'+sucsubcategory.id" class="label-text3">{{sucsubcategory.name}}</label>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data(){
    return {
      tituloboton: "Expandir",
      shouldExpandAll: false,
    }
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts'
    }),
    ...mapMutations({
      setFilterCategories: 'ecommerce/SET_FILTER_CATEGORIES',
      setPageNumber: 'ecommerce/SET_PAGE_NUMBER'
    }),
    toggleSubMenu(event, forceExpand) {
      const buttonElement = event.currentTarget;
      const liElement = buttonElement.closest("li");
      const ulElement = liElement.querySelector(".widget-categories__item");
      const isExpanded = ulElement.classList.contains("widget-categories__item--open");

      const expand = typeof forceExpand === "boolean" ? forceExpand : !isExpanded;
      ulElement.classList.toggle("widget-categories__item--open", expand);
      buttonElement.classList.toggle("button__open", expand);
    },
    toggleAllCategories() {
      this.shouldExpandAll = !this.shouldExpandAll

      const liElements = document.querySelectorAll(".widget-categories__item");
      const buttonElements = document.querySelectorAll(".widget-categories__expander");
      liElements.forEach((liElement) => {
        liElement.classList.toggle("widget-categories__item--open", this.shouldExpandAll);
      });
      buttonElements.forEach((buttonElement) => {
        buttonElement.classList.toggle("button__open", this.shouldExpandAll);
      });

      this.tituloboton = this.shouldExpandAll ? "Contraer" : "Expandir";

    }
  },
  computed: {
    ...mapGetters({
      categoryList: "ecommerce/categoryList",
      searchdata: "ecommerce/searchdata",
      categoryTree: "ecommerce/categoryTree"

    }),
    selected: {
      get() {
        return this.searchdata.filters.category
      },
      set(value) {
        this.setFilterCategories(value);
        this.setPageNumber(1);
        this.loadProducts();
      }
    }
  },
};
</script>
<style scoped>
.widget-categories {
  position: relative;
  padding-right: 5px;
}

.categoryline {
  display: grid;
  width: 100%;
  grid-template-columns: 75% 25%;
}
.paddingleft {
  padding-left: 10px;
}
.paddingleft2 {
  padding-left: 20px;
}
.margen {
  margin-bottom: 10px;
}
.widget-categories__item {
  overflow: hidden;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.widget-categories__expander {
  margin-top: -2px;
  width: 28px!important;
  height: 28px!important;
  position: relative;
  border: none;
  border-radius: 1.5px;
  cursor: pointer;
  padding: 0;
  background: transparent;
  color: #494949;
  transition: all 0.2s;
  justify-self: end;
}
.widget-categories__expander:focus,
.widget-categories__expander:hover {
  background: #f2f2f2;
  color: #a6a6a6;
}
.widget-categories__expander:focus {
  outline: none;
}
.widget-categories__expander:after,
.widget-categories__expander:before {
  display: block;
  position: absolute;
  content: '';
  background: currentColor;
}
.widget-categories__expander:before {
  width: 8px;
  height: 2px;
  left: calc(50% - 4px);
  top: calc(50% - 1px);
}
.widget-categories__expander:after {
  width: 2px;
  height: 8px;
  left: calc(50% - 1px);
  top: calc(50% - 4px);
  transition: transform 0.2s;
}
.sidebar .label-text {
  font-size: 16px;
}
.sidebar .label-text2 {
    cursor: pointer;
    font-size: 13px;
    color: #555;
    margin: 0;
    padding-left: 20px;
    position: relative;
    font-weight: normal;
}
.sidebar .label-text3 {
    cursor: pointer;
    font-size: 10px;
    color: #979797;
    margin: 0;
    padding-left: 20px;
    position: relative;
    font-weight: normal;
}
.button__open.widget-categories__expander:after {
  transform: rotate(90deg);
}
.widget-categories__item--open.widget-categories__item {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
}
.single_add_to_cart_button:hover {
  background-color: var(--accent-color-dark);
}
.single_add_to_cart_button:active {
  background-color: var(--accent-color-light);
}
</style>
