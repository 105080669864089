<template>
  <div>
    <div class="main-content main-content-product left-sidebar">
      <div class="container">
        <div class="row">
          <GridProducts/>
          <Sidebar/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import GridProducts from "../components/GridProducts.vue";
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    Sidebar,
    GridProducts,
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts'
    }),
    ...mapMutations({
      setSortMethod: 'ecommerce/SET_SORT_METHOD'
    }),
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
        vm.setSortMethod('abc');
        vm.loadProducts();
    })
  }
};
</script>

<style></style>
