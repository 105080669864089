<template>
<div>

<div class="widget widget-brand">
    <div class="mb-3">
      <h3 class="widgettitle">Marcas</h3>
      <button type="button" class="single_add_to_cart_button btn-block btn-sm button"  @click="toggleAllBrands">{{ tituloboton }}</button>
    </div>
    <ul class="list-brand list-brands">
        <li v-for="(brand, index) in brands" :key="index">
            <input :id="'brand_'+brand.id" type="checkbox"
                v-model="selected"
                v-bind:value="brand.id"
            >
            <label :for="'brand_'+brand.id" class="label-text">{{brand.name}}</label>
        </li>
    </ul>
</div>

</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data(){
    return {
      tituloboton: "Expandir",
      shouldExpandAll: false,
    }
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts'
    }),
    ...mapMutations({
      setFilterBrands: 'ecommerce/SET_FILTER_BRAND',
      setPageNumber: 'ecommerce/SET_PAGE_NUMBER'
    }),
    toggleAllBrands() {
      this.shouldExpandAll = !this.shouldExpandAll
      const ulBrands = document.querySelector(".list-brands")
      ulBrands.classList.toggle("list-brands__open", this.shouldExpandAll)
      this.tituloboton = this.shouldExpandAll ? "Contraer" : "Expandir";
    }
  },
  mounted() {
    if (this.searchdata.filters.brand.length>0){
        this.selected= this.searchdata.filters.brand;
    }
  },
  computed: {
    ...mapGetters({
      searchdata: "ecommerce/searchdata",
      brands: "ecommerce/brands"
    }),
    selected: {
      get() {
        return this.searchdata.filters.brand
      },
      set(value) {
        this.setFilterBrands(value);
        this.setPageNumber(1);
        this.loadProducts();
      }
    }
  }
};
</script>
<style scoped>
.list-brands {
  overflow: hidden;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.list-brands__open.list-brands {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
}
</style>