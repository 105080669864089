<template>
  <div id="GridProducts" class="content-area shop-grid-content no-banner col-lg-10 col-md-9 col-sm-12 col-xs-12" >
    <div class="site-main">
      <!--
      <div class="breadcrumb-trail breadcrumbs">
        <ul class="trail-items breadcrumb">
            <li class="trail-item trail-begin">
                <router-link :to="{ name: 'Home' }"> Principal </router-link>
            </li>
            <li class="trail-item trail-end active">Productos</li>
        </ul>
      </div>
      -->
      <div v-if="products.length > 0">
        <ShopSortControl
          :productsOnPages="itemCount"
          :defaultSortMethod ="sortMethod"
          v-on:changeQuantity="updateProductsOnPages($event)"
          v-on:changeSortMethod="updateSortMethod($event)"
        />
          <ul class="row list-products auto-clear equal-container product-grid">
            <li v-for="product in products" :key="product.id"
              class="product-item product-type-variable col-lg-3 col-md-4 col-sm-4 col-xs-6 col-ts-12 style-1">
              <ProductCard :product="product" />
            </li>
          </ul>
      </div>
      <div v-else>
        <h3 class="text-center" style="margin-top: 100px">
          <strong>No se encontró ningun producto</strong>
        </h3>
      </div>
      <GridPagination v-if="products.length > 0" />
    </div>
  </div>
</template>

<script>
import ProductCard from "../components/Product-card.vue";
import ShopSortControl from "../components/gridProducts/ShopSortControl.vue";
import GridPagination from "../components/gridProducts/GridPagination.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "TiendaIndex",

  components: {
    ProductCard,
    ShopSortControl,
    GridPagination,
  },
  data() {
    return {
        paginate: ["products","totalPages"],
        itemCount:8,
        sortMethod: 'abc'
    }
  },
  beforeDestroy(){
    this.setPageNumber(1);
  },
  computed: {
    ...mapGetters({
      products: 'ecommerce/products'
    }),
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts'
    }),
    ...mapMutations({
      setPageNumber: 'ecommerce/SET_PAGE_NUMBER',
      setItemCount: 'ecommerce/SET_ITEM_COUNT',
      setSortMethod: 'ecommerce/SET_SORT_METHOD'
    }),
    updateProductsOnPages(updatedQuantity) {
      this.setPageNumber(1);
      this.itemCount = updatedQuantity;
      this.setItemCount(this.itemCount);
      this.loadProducts();
    },
    updateSortMethod(sortMethod) {
      this.setPageNumber(1);
      this.sortMethod = sortMethod;
      this.setSortMethod(this.sortMethod);
      this.loadProducts();
    }
  },

};
</script>
<style scoped>
.site-main {
  margin-top: 30px;
}
</style>
